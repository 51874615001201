.hero {
  /* padding: 19px 9.615% 22px; */

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.hero .info {
  width: 500px;
  margin-bottom: 3rem;
  text-align: center;
}

.hero .pic {
  width: 500px;
}
img {
  margin: 0 auto;
}
