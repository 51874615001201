.container {
  width: 87.5%;
  max-width: 1280px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

article section {
  padding-top: 4em;
  section {
    padding-top: 2em;
  }
}

h1,
h2,
h3 {
  padding: 1em 0;
}

ul {
  list-style: none;
}
ol {
  padding-left: 1em;
}
